<!--
 * @Author: chenwc
 * @Date: 2021-09-13 16:48:12
 * @LastEditTime: 2022-02-18 18:29:26
 * @LastEditors: Please set LastEditors
 * @Description: 改用element组件
 * @FilePath: /linghtning-r99/src\views\Email\addEmail.vue
-->
<template>
  <!-- 添加邮件 -->
  <div class="cloudcc_email">
    <h2>
      <svg class="title_img" aria-hidden="true">
        <use href="#icon-email11"></use>
      </svg>
      <!-- Email to CloudCC -->
      <span>{{ $t("label_emailtocloudcc_titel") }}</span>
    </h2>
    <!-- 加载样式 -->
    <div v-if="loading" class="loading_box">
      <div style="margin-top: -30vh">
        <img
          src="../../assets/images/email/loading1.gif"
          style="width: 200px; height: 180px"
        />
        <!-- 数据保存中 ··· -->
        <h3 v-show="flag" style="padding-left: 40px">
          {{ $t("label.email.saveing") }}
        </h3>
      </div>
    </div>
    <div class="add_email">
      <el-form ref="form1" :model="form1" :rules="rules" label-width="150px">
        <div class="email_input">
          <!-- 配置邮件归属 -->
          <h3 class="email_input_title">
            <!-- 配置邮件归属 -->
            {{ $t("label_emailtocloudcc_step2_name") }}
          </h3>
          <!-- Email to -->
          <el-form-item
            :label="$t('label_emailtocloudcc_filed_emailto')"
            prop="cctype"
          >
            <el-select
              v-model="form1.cctype"
              :placeholder="this.$i18n.t('label_emailtocloudcc_select1')"
              @change="handleChange"
            >
              <el-option
                :label="$t('label_emailtocloudcc_step1_case1')"
                :value="1"
              ></el-option>
              <el-option
                :label="$t('label_emailtocloudcc_step1_lead1')"
                :value="2"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 记录所有人 -->
          <el-form-item
            :label="$t('label_emailtocloudcc_filed_owner')"
            prop="ccname"
          >
            <div @click="showModal" style="width: 210px">
              <el-input
                v-model="form1.ccname"
                :placeholder="$t('label_emailtocloudcc_select1')"
                autocomplete="new-password"
              >
                <!-- 图标插槽 -->
                <svg slot="suffix" class="imgSvg" aria-hidden="true">
                  <use href="#icon-search"></use>
                </svg>
              </el-input>
            </div>
          </el-form-item>
        </div>
        <div class="email_input email_set_input">
          <!-- 设置邮箱信息 -->
          <h3 class="email_input_title">
            {{ $t("label_emailtocloudcc_set") }}
          </h3>
          <!-- gmail outlook邮箱界面 -->
          <div
            class="gmail_outlook"
            :style="{ display: emailfirstshow ? 'flex' : 'none' }"
          >
            <!-- 非中国地区gmail outlook邮箱添加 -->
            <div
              class="email_img_box"
              v-show="country !== 'china'"
              @click="addGmail"
            >
              <div class="img_box gmail_img">
                <svg class="img" aria-hidden="true">
                  <use href="#icon-gmail"></use>
                </svg>
              </div>
              <span class="img_name gmail_name">Gmail</span>
            </div>
            <!-- 中国地区gmail outlook邮箱添加 -->
            <div
              class="email_img_box"
              v-show="country == 'china'"
              @click="chinaAddEmail"
            >
              <div class="img_box gmail_img">
                <svg class="img1" aria-hidden="true">
                  <use href="#icon-gmail"></use>
                </svg>
              </div>
              <span class="img_name gmail_name">Gmail</span>
            </div>
            <!-- Outlook.com邮箱添加 -->
            <div class="email_img_box" @click="addOutlook">
              <div class="img_box outlook_img">
                <svg class="img1" aria-hidden="true">
                  <use href="#icon-outlook"></use>
                </svg>
              </div>
              <span class="img_name outlook_name">Outlook.com</span>
            </div>
            <!-- Other邮箱添加 -->
            <div class="email_img_box" @click="showImap">
              <div class="img_box other_img">
                <svg class="img3" aria-hidden="true">
                  <use href="#icon-other"></use>
                </svg>
              </div>
              <span class="img_name other_name">Other</span>
            </div>
          </div>
          <!-- 除 gmail outlook外其他邮箱界面 -->
          <div
            class="imap_box"
            :style="{ display: emailfirstshow ? 'none' : 'block' }"
          >
            <!-- 邮箱用户名 -->
            <el-form-item
              :label="$t('label_emailtocloudcc_filed_username')"
              prop="syncmail"
            >
              <el-input
                v-model="form1.syncmail"
                autocomplete="new-password"
              ></el-input>
            </el-form-item>
            <!-- 邮箱密码 -->
            <el-form-item
              :label="$t('label_emailtocloudcc_filed_password')"
              prop="passWordValue"
            >
              <el-input
                v-model="form1.passWordValue"
                :type="showpassword ? 'text' : 'password'"
                :readonly="readonly"
                @blur="readonly = true"
                @click.native="readonly = false"
              >
                <svg
                  class="eaerSvg"
                  slot="suffix"
                  aria-hidden="true"
                  @click="changePassword"
                >
                  <use :href="eyesvg"></use>
                </svg>
              </el-input>
            </el-form-item>
            <!-- 服务器类型 -->
            <div style="display: flex">
              <!-- 服务器类型 -->
              <el-form-item
                :label="$t('label_emailtocloudcc_filed_servertype')"
                prop="serverType"
              >
                <el-input
                  autocomplete="new-password"
                  class="server_type"
                  type="text"
                  value="IMAP"
                  disabled
                ></el-input>
              </el-form-item>

              <!-- 服务器 -->
              <el-form-item
                :label="$t('label_emailtocloudcc_filed_server')"
                prop="imaphost"
              >
                <el-input
                  v-model="form1.imaphost"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
              <!-- 端口 -->
              <el-form-item
                :label="$t('label_emailtocloudcc_filed_port')"
                class="part_form_item"
                prop="imapport"
              >
                <el-input
                  v-model.number="form1.imapport"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
              <!-- 安全链接 -->
              <el-form-item class="email_check_box">
                <el-checkbox v-model="form1.isimapssl">{{
                  $t("label_emailtocloudcc_filed_ssl")
                }}</el-checkbox>
              </el-form-item>
            </div>
            <!-- 服务器类型 -->
            <div style="display: flex">
              <!-- 服务器类型 -->
              <el-form-item
                :label="$t('label_emailtocloudcc_filed_servertype')"
                prop="serverType"
              >
                <el-input
                  class="server_type"
                  type="text"
                  value="SMTP"
                  disabled
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>

              <!-- 服务器 -->
              <el-form-item
                :label="$t('label_emailtocloudcc_filed_server')"
                prop="smtphost"
              >
                <el-input
                  v-model="form1.smtphost"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
              <!-- 端口 -->
              <el-form-item
                :label="$t('label_emailtocloudcc_filed_port')"
                class="part_form_item"
                prop="smtpport"
              >
                <el-input
                  v-model.number="form1.smtpport"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
            </div>
            <div class="btn_box">
              <!-- 上一步 -->
              <button class="back_btn" @click="showGmail">
                {{ $t("label.import.page1.laststep") }}
              </button>
              <!-- 完成 -->
              <button class="add_btn" @click="addEmail">
                {{ $t("label_emailtocloudcc_button_complete") }}
              </button>
            </div>
          </div>
        </div>
      </el-form>

      <!-- 记录所有人模态框查找 -->
      <el-dialog
        :title="this.$i18n.t('label_emailtocloudcc_search')"
        :visible.sync="visible"
        width="1200px"
      >
        <search-table
          ref="searchTable"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          :isShowClearBtn="false"
          @changeSelect="clickRow"
        />
      </el-dialog>

      <!-- 模态框错误提示 -->
      <div class="errorBox" v-show="isOk">
        <div class="error">
          <div class="error_title">
            <i class="el-icon-warning-outline" style="color: red"></i>
            <span>{{ this.errorTitle }}</span>
          </div>
          <!-- 确认 -->
          <button @click="confirmbtn">
            {{ $t("label.emailtocloudcc.button.sure") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchData from "@/config/fetch";
import SearchTable from "@/components/Form/search-table.vue";
export default {
  name: "AddEmail",
  components: {
    SearchTable,
  },
  data() {
    return {
      readonly: true, // 解决密码自动填充
      relevantPrefix: "005", // 查找对象的前缀
      relevantObjId: "user", // 查找对象的api
      form1: {
        cctype: "", // Email to
        ccname: "", // 记录所有人
        syncmail: "", // 邮箱用户名
        passWordValue: "", // 邮箱密码
        imaphost: "", // ima服务器
        imapport: "", // ima端口
        isimapssl: true, // 安全链接
        smtphost: "", // smt服务器
        smtpport: "", // smt端口
        serverType: "imap", // 服务器类型
      }, // 表单对象
      rules: {
        cctype: [
          {
            required: true,
            message: this.$i18n.t("label.select.email.type"),
            trigger: "blur",
          },
        ],
        ccname: [
          {
            required: true,
            message: this.$i18n.t("label.emailtocloudcc.select3"),
            trigger: "blur",
          },
        ],
        syncmail: [
          {
            required: true,
            message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
            trigger: "blur",
          },
        ],
        passWordValue: [
          {
            required: true,
            message: this.$i18n.t("label_emailtocloudcc_redmessage2"),
            trigger: "blur",
          },
        ],
        imaphost: [
          {
            required: true,
            message: this.$i18n.t("label_emailtocloudcc_redmessage3"),
            trigger: "blur",
          },
        ],
        imapport: [
          {
            required: true,
            message: this.$i18n.t("label_emailtocloudcc_redmessage4"),
            trigger: "blur",
          },
          {
            type: "number",
            message: this.$i18n.t("label.field.input.number"),
            trigger: "blur",
          },
        ],
        smtphost: [
          {
            required: true,
            message: this.$i18n.t("label_emailtocloudcc_redmessage3"),
            trigger: "blur",
          },
        ],
        smtpport: [
          {
            required: true,
            message: this.$i18n.t("label_emailtocloudcc_redmessage4"),
            trigger: "blur",
          },
          {
            type: "number",
            message: this.$i18n.t("label.field.input.number"),
            trigger: "blur",
          },
        ],
        serverType: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
      }, // 表单验证
      eyesvg: "#icon-hide", // 眼睛图标
      flag: false, // loading
      token: "", // token
      returnurl: "", // 返回路由
      lange: "", // 多语言字段
      groupid: "", // 组织id
      userid: "", // 用户id
      country: "china", // 国家
      smtphost: "", // smtp 服务器地址
      smtpport: "", // smtp 服务器端口
      errorTitle: "", // 错误提示内容
      isOk: false, // 错误提示模态框是否显示
      ccownerid: "", // 用户id
      emailValue: "", // 邮箱账号
      passWordValue: "", // 密码
      imaphost: "", // imap 服务器地址
      imapport: "", // imap 服务器端口
      isimapssl: "", // imap是否使用ssl连接
      owerList: [null], // 用户列表
      visible: false, // 模态框
      emailfirstshow: true, // 邮箱信息显示是否显示图片部分
      loading: false, //  loading加载
      showpassword: false, // 是否显示密码
      owervalue: "", // 记录所有人值
      // 表单数据
      checkNick: false,
      /** 多语言变量 */
      emailto: this.$i18n.t("label_emailtocloudcc_filed_emailto"),
    };
  },
  created() {
    this.userid = this.$route.query.userid;
    this.groupid = this.$route.query.groupid;
    this.lange = this.$route.query.lange;
    this.returnurl = this.$route.query.rtnURL;
  },
  mounted() {
    // 关闭loading
    document.getElementById("lightning-loading").style.display = "none";
    // 获取token
    fetchData(
      window.Glod["ccex-emailsync"] + "/jwtgettoken",
      { userid: this.userid, groupid: this.groupid },
      function (res) {
        let data = eval(res);
        this.token = data.token;
      }.bind(this)
    );
  },
  methods: {
    // 记录所有人列表行点击事件
    clickRow(row) {
      this.form1.ccname = row.data.name;
      this.ccownerid = row.data.id;
      this.owervalue = row.data.name;
      this.visible = false;
      //  重新校验记录所有人
      this.$refs.form1.validateField("ccname");
    },
    // 显示imap邮箱信息
    showImap() {
      this.emailfirstshow = false;
    },
    // 显示 outlook gmail邮箱
    showGmail() {
      this.emailfirstshow = true;
    },
    // 是否显示错误提示模态框
    confirmbtn() {
      this.isOk = false;
    },
    //添加邮箱
    addEmail(e) {
      e.preventDefault();

      this.$refs.form1.validate((err) => {
        if (err) {
          this.flag = true;
          this.loading = true;

          fetchData(
            window.Glod["ccex-emailsync"] + "/jwtaddimapmail",
            {
              token: this.token,
              ccownerid: this.ccownerid,
              cctype: this.form1.cctype,
              email: this.form1.syncmail,
              password: this.form1.passWordValue,
              imaphost: this.form1.imaphost,
              imapport: this.form1.imapport,
              isimapssl: this.form1.isimapssl,
              issmtpssl: true,
              smtpport: this.form1.smtpport,
              smtphost: this.form1.smtphost,
            },
            // 100 -- 未知状态  200 -- 正确  401 -- 后台配置错误  402 -- 参数错误  403 -- 数据库错误
            // 404 -- 无效token 405 --  455 -- imap认证失败 456 -- smtp认证失败

            function (res) {
              let data = eval(res);
              if (data.code == 200) {
                this.$router.push({
                  path: `/list?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&code=200`,
                });
                this.loading = false;
              } else {
                this.isOk = true;
                if (data.code == 100) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage5"
                  );
                } else if (data.code == 455 || data.code == 456) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage6"
                  );
                } else if (data.code == 401) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage7"
                  );
                } else if (data.code == 402) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage8"
                  );
                } else if (data.code == 403) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage9"
                  );
                } else if (data.code == 404) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage10"
                  );
                } else if (data.code == 405) {
                  if (this.cctype == 1) {
                    this.errorTitle = this.$i18n.t(
                      "label_emailtocloudcc_exists2"
                    );
                  } else if (this.cctype == 2) {
                    this.errorTitle = this.$i18n.t(
                      "label_emailtocloudcc_exists1"
                    );
                  } else {
                    this.errorTitle = this.$i18n.t(
                      "label_emailtocloudcc_exists"
                    );
                  }
                }
                this.loading = false;
              }
            }.bind(this)
          );
        }
      });
    },
    // 添加gmail邮箱
    addGmail(e) {
      e.preventDefault();
      this.flag = true;
      this.loading = true;
      this.form1.validate(["owervalue", "cctype"], (errors, values) => {
        // ...
        if (errors) {
          fetchData(
            window.Glod["ccex-emailsync"] + "/login/googlemail",
            {
              token: this.token,
              ccownerid: this.ccownerid,
              cctype: values.cctype,
              returnurl: `${this.returnurl}/#/list?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}`, // 测试环境
            },
            function (res) {
              let data = eval(res);
              if (data.code == 200) {
                window.location.href = data.redirecturl;
                // this.loading = false;
              }
            }.bind(this)
          );
        } else {
          this.loading = false;
        }
      });
    },
    // 中国添加gmail提示
    chinaAddEmail() {
      this.isOk = true;
      this.errorTitle = this.$i18n.t("label_function_tip");
    },
    // 添加outlook邮箱
    addOutlook(e) {
      e.preventDefault();
      this.flag = true;
      this.loading = true;
      this.form1.validate(["owervalue", "cctype"], (err, values) => {
        if (err) {
          fetchData(
            window.Glod["ccex-emailsync"] + "/login/outlook",
            {
              token: this.token,
              ccownerid: this.ccownerid,
              cctype: values.cctype,
              returnurl: `${this.returnurl}/#/list?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}`,
            },
            function (res) {
              let data = eval(res);
              if (data.code == 200) {
                window.location.href = data.redirecturl;
                // this.loading = false;
              }
            }.bind(this)
          );
        } else {
          this.loading = false;
        }
      });
    },

    // 选择邮箱类型
    handleChange(value) {
      this.cctype = Number(value);
    },
    // 模态框
    showModal() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 是否显示密码
    changePassword() {
      this.showpassword = !this.showpassword;
      if (this.showpassword) {
        this.eyesvg = "#icon-emailtrack";
      } else {
        this.eyesvg = "#icon-hide";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 分页
.page {
  display: flex;
  justify-content: flex-end;
}
// 统一管理input长度
::v-deep .el-input {
  width: 210px !important;
}
// 加载样式
.loading_box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  opacity: 0.9;
  z-index: 100;
}
.imgSvg {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: top;
  margin: 10px auto;
}
.cloudcc_email {
  height: 100%;
  width: 100%;
  // background: #f2f2f5;
  padding: 18px 10px 0 10px;
  h2 {
    font-size: 20px;
    color: #333333;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      font-family: Helvetica;
    }
    .title_img {
      width: 35px;
      height: 35px;
      margin-right: 20px;
    }
  }
}
.add_email {
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  margin-top: 16px;
  padding: 15px 20px 40px;
}

.add_email .email_set_input {
  margin-top: 20px;
}
.add_email .email_input_title {
  height: 30px;
  background: #f8f8f8;
  line-height: 30px;
  font-weight: normal;
  padding-left: 10px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  margin-bottom: 20px;
}
.add_email .gmail_outlook {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  padding-bottom: 160px;
}
.add_email .email_img_box:hover {
  cursor: pointer;
}
.add_email .email_img_box {
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 11.2px;
  width: 180px;
  height: 180px;
  text-align: center;
  .img {
    width: 78px;
    height: 63px;
  }
  .img1 {
    width: 62px;
    height: 62px;
  }
  .img3 {
    width: 60px;
    height: 63px;
  }
}
.add_email .ower_box {
  position: relative;
  width: 180px;
  /* border: 1px solid #D8DDE6;
		border-radius: 2px; */
  height: 32px;
}
.add_email .ower_box input {
  height: 32px;
}
.add_email .ower_box .ower_search {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 99;
  top: 5px;
  right: 4px;
  background: #fff;
  text-align: center;
}
.add_email .ower_box .ower_search img {
  width: 20px;
  vertical-align: top;
  margin: 4px auto;
}
.add_email .email_img_box .img_box {
  margin: 40px auto 22px;
}
.add_email .imap_box {
  display: none;
}
.add_email .email_input_box {
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 20px;
}
.add_email .email_input_box label {
  width: 88px;
  text-align: right;
  margin-right: 12px;
}
.add_email .email_input_box label span {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #0b0000;
  letter-spacing: 0;
  text-align: right;
}
.add_email .email_input_box label .must_icon {
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
  margin-right: 4px;
}
.add_email .email_input_box input {
  height: 30px;
  width: 180px;
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 2px;
  padding-left: 10px;
}
/* 密码是否显示样式 */
.add_email .password_box {
  position: relative;
}
.add_email .password_box .password_icon {
  height: 28px;
  width: 28px;
  display: inline-block;
  position: absolute;
  // top: 6px;
  right: 1px;
}
.eaerSvg {
  display: inline-block;
  height: 20px;
  width: 28px;
  margin-top: 10px;
}

/* 密码是否显示样式结束 */
.add_email .email_input_box .server_type {
  color: rgba(23, 49, 92, 0.3);
  border: 1px solid #d8dde6;
}
.add_email .part_form_item {
  margin-bottom: 20px;
}
.add_email .email_check_box {
  margin-left: 84px;
  /* margin-top: 7px; */
  height: 20px;
}
.add_email .search_info {
  margin-bottom: 20px;
}
.add_email .btn_box {
  margin-left: 90px;
  margin-top: 48px;
}
.add_email button {
  // width: 60px;
  // height: 30px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  background: #66b1ff;
  border-radius: 3px;
  border: none;
  outline: none;
  box-sizing: border-box;
}
.add_email button:hover {
  cursor: pointer;
}
.add_email .back_btn {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  color: #333330;
  margin-right: 5px;
}

.errorBox {
  background: rgba(16, 16, 16, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1111;
}
.error {
  width: 500px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  text-align: center;
  padding: 30px;
}
.error_title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.error span {
  display: block;
  width: 100%;
  text-align: left;
}
.error_icon {
  color: #f5222d;
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
}
</style>